import "./App.css";
import screenshot from "./assets/screenshot.svg";
import apple from "./assets/apple.svg";
import android from "./assets/android.svg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">Rexpand</h1>
      </header>
      <main className="App-body">
        <div className="flex-item">
          <div>
            <p className="body-p-1">- Coming soon</p>
            <p className="body-p-2">发现你职场生涯的下一站</p>
            <p className="body-p-2">Plan your next career move on Rexpand</p>
          </div>
          <div className="flex-between">
            <button>
              <img src={apple} alt="Apple store icon" />
              <div className="button-text-b">
                <div className="text-1">Download on the</div>
                <div className="text-2">App Store</div>
              </div>
            </button>
            <button>
              <img src={android} alt="Google play icon" />
              <div className="button-text-b">
                <div className="text-1">Get it on</div>
                <div className="text-2">Google Play</div>
              </div>
            </button>
          </div>
        </div>
        <div className="flex-item flex-center ">
          <img src={screenshot} alt="App on iphone" />
        </div>
      </main>
      <footer>
        <h2 className="App-title-footer">Rexpand</h2>
        <p>CONTACT</p>
        <p>development@rexpandco.com</p>
      </footer>
    </div>
  );
}

export default App;
